

/* FollowersAreaChart.css */
.chart-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.chart-card1,
.chart-card2 {
    width: 100%;
}

@media (min-width: 768px) {
    .chart-container {
        flex-direction: row;
    }

    .chart-card1,
    .chart-card2 {
        width: 50%;
        margin-bottom: 0;
    }
}
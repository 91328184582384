.sub__menu-admin {
  position: fixed;
  left: 65px;
  top: 360px;
  z-index: 999;
  background-color: white;
  color: rgb(109, 105, 105);
  border-radius: 10px;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  border: 0.3px solid rgb(221, 215, 215);
  padding: .3rem;
  opacity: 0;
  height: 0px;
  transition: .05s ease-in;
  width: 10rem;
  display: none;
}

.sub__menu-admin.open {
  /* clip-path: polygon(50% 0%, 100% 0, 100% 60%, 100% 100%, 0 100%, 0% 60%, 0 0); */
  opacity: 1;
  height: 126px;
  display: initial;
}

.sub__menu-admin > li {
  list-style: none;
  width: 100%;
  /* margin: .25rem 0; */
  /* padding: .48rem 0; */
  border-radius: 8px;
  /* background-color: red; */
}

.sub__menu-admin > li > a {
  margin: .1rem 0;
  padding: .6rem 1rem;
  display: block;
  text-decoration: none;
  text-align: start;
  width: 100%;
  /* text-align: center; */
  color: rgb(82, 79, 79);
  font-weight: 500;
  border-radius: 8px;
  font-size: .9rem;
}

.sub__menu-admin > li > a:hover {
  background-color: #1111;
}

.payment_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  width: 70%;
}

.payment_container form {
  padding: 20px;
  width: 100%;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.payment_container form {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
}

.payment_container form .col {
  width: 100%;
}

.payment_container form  .col .title {
  font-size: 20px;
  color: #333;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.payment_container form  .col .inputBox {
  margin: 15px 0;
}

.payment_container form  .col .inputBox span {
  margin-bottom: 10px;
  display: block;
}

.payment_container form  .col .inputBox input {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px 15px;
  font-size: 15px;
  text-transform: none;
}

.payment_container form  .col .inputBox input:focus {
  border: 1px solid #000;
}

.payment_container form  .col .flex {
  display: flex;
  gap: 15px;
  width: 100%;
}

.payment_container form  .col .flex .inputBox {
  margin-top: 5px;
}

.payment_container form  .col .inputBox img {
  height: 34px;
  margin-top: 5px;
  filter: drop-shadow(0 0 1px #000);
}

.payment_container form .submit-btn {
  width: 100%;
  padding: 12px;
  font-size: 17px;
  color: #fff;
  margin-top: 5px;
  background: #2e46cc;
  cursor: pointer;
}

.payment_container form .submit-btn:hover {
  background: #2730ae;
}

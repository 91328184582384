.TC-container > div {
  color: rgb(59, 58, 58);
}

.TC-container > div > h2,
.TC-container > div > p,
.TC-container > div > b,
.TC-container > div > ol {
  margin: 1rem 0;
}

.TC-container > div > ol > li{
    margin: .5rem 0;
}
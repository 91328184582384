.sidebar__menubar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sidebar__menubar > h2 {
  width: 100%;
  text-align: start;
  color: rgb(116, 116, 116);
  font-size: 1rem;
  font-weight: 600;
  margin: 1rem 0;
  margin-left: 3.4rem;
  margin-bottom: 0;
}

.sidebar__menubar-list {
  width: 100%;
  list-style: none;
  padding: 0 1rem;
  margin: 0.3rem 0;
  display: flex;
  justify-content: center;
}

.sidebar__menubar-list > a {
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0.4rem;
  padding: 0.5rem 0;
  border-radius: 10px;
  position: relative;
  font-weight: 600;
  color: rgb(133, 127, 127);
}

.sidebar__menubar-list > a > div {
  width: 100%;
  /* flex: 1; */
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  color: black;
}

.sidebar__menubar-list > a > div > span {
  color: rgb(73, 69, 69);
  font-size: 0.95rem;
}

.sidebar__menubar-list > a > div > svg {
  margin-right: 0.4rem;
  margin-left: 0.87rem;
  color: rgb(65, 61, 61);
}

.sidebar__menubar-list > a:hover {
  background-color: #1111;
  color: black;
}

.sidebar__menubar-list > a > svg {
  position: absolute;
  top: 30%;
  left: 85%;
  font-size: 1.2rem;
  transform: rotate(0deg);
  transition: 0.1s ease-in-out;
}

.sidebar__menubar-list > a > svg.open {
  transform: rotate(-180deg);
  transition: 0.2s ease-in-out;
}

.sidebar__menubar > div {
  padding: 0.3rem;
  padding-top: 0%;
  transition: 0.3s ease-in;
  width: 85%;
  margin-left: auto;
  /* clip-path: polygon(50% 0%, 100% 0, 99% 0, 0 0, 0% 60%, 0 0); */
  opacity: 0;
  height: 0px;
  transition: 0.2s ease;
}

.sidebar__menubar > div.open {
  opacity: 1;
  height: 120px;
  /* transition: .4s ease;
  clip-path: polygon(50% 0%, 100% 0, 100% 60%, 100% 100%, 0 100%, 0% 60%, 0 0); */
}

.sidebar__menubar > div > li {
  list-style: none;
  width: 90%;
  margin: 0.25rem 0;
  /* padding: 0.48rem 0; */
  border-radius: 8px;
  cursor: pointer;
}

.sidebar__menubar > div > li > a {
  /* margin: 1rem 0; */
  display: block;
  /* flex: 1; */
  padding: 0.6rem 1rem;
  text-decoration: none;
  width: 100%;
  text-align: start;
  color: rgb(37, 37, 37);
  font-weight: 500;
  border-radius: 8px;
  font-size: 0.9rem;
}

.sidebar__menubar > div > li > a:hover {
  background-color: #1111;
  color: black;
}

.skills-tooltip {
  max-width: 300px !important;
  background-color: var(--white-color) !important;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  padding: 1rem !important;
  color: var(--gray-color) !important;
  text-align: center !important;
  line-height: 1.5 !important;
  opacity: 1 !important;
}
@media screen and (min-width: 2000px) {
  .skills-tooltip {
    font-size: 1.75rem !important;
    max-width: 500px !important;
    line-height: 2 !important;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
}


input {
  /* font-size: 50%; */
  font-size: 44%;
}

.no-scroll-display::-webkit-scrollbar {
  display: none;
  width: 0%;
}


.h-\[25\.5rem\] {
  height: 30.5rem;
}


.border-rose-500 {
  height: 157px;
}


.font-semibold {
  font-weight: 900;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 900;
}



@media screen and (min-width: 1024px) {
  .text-sm {
    font-size: 0.875rem;
    font-weight: 600;
  }
}

@media (min-width: 1536px) {
  .dropdown {
    max-width: 1536px;
    color: black;
    font-size: medium;
    font-weight: 700;
  }
}

@media (max-width: 1024px) {
  .inputfile {
    margin-left: 45px;
  }
}


.tremor-Icon-root {
  font-size: 520px;
}



.Subscriptiondiv {
  height: 156px;
}

.tremor-Button-root {
  margin-top: 15px;
}


@media screen and (max-width: 360px) {
  #filter-drawer {
    width: 60%;
    margin-right: 45px;
    padding-left: 1px;
  }

  .FilterResetBtn {
    margin-left: 54px;
  }
}


@media screen and (max-width: 414px) {
  .auth_container .modal-left__logo {
    margin-left: 20px;
  }

  .modal-left {
    width: 100%;
    padding: 0 20px;
  }

  .modal-right {
    display: none;
  }

  .form-container1 {
    padding: 20px;
  }

  .input-group1 {
    width: 100%;
  }

  .GoogleLoginBtn {
    padding: 8px 3px;
  }
}



@media screen and (max-width: 360px) {
  .imagesNo {
    margin-left: 45px;
  }
}

.html2pdf_page-break {
  page-break-before: always;
}
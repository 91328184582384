
.dropdown1 {
    position: relative;
    top: 5px;
    margin: 0 auto;
    width: 220px;
    background: #f0f3f4;
}

.dropdown__text1 {
    color: black;
    padding: 10px 16px;
    cursor: pointer;
    height: auto;
}

.dropdown__text1:hover {
    color: black;
    background: #f0f3f4;
}

.dropdown__text1:after {
    content: "";
    transition: all .3s;
    border: solid #ccc;
    border-width: 0 1px 1px 0;
    float: right;
    margin-top: 8px;
    margin-right: 6px;
    padding: 5px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.dropdown1.active .dropdown__text1:after {
    margin-top: 1px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.dropdown__items1 {
    max-height: 0px;
    transition: max-height 0.6s ease;
}

.dropdown1.active .dropdown__items1 {
    visibility: hidden;
    height: auto;
    max-height: 290px;
    opacity: 1;
    transition: max-height 0.7s, opacity 3s, visibility 4s ease;
}

.dropdown__item1 {
    cursor: pointer;
    padding: 10px 18px;
    color: rgb(31, 29, 29);
    border-radius: 10px;
    font-weight: 600;
}



.dropdown__item1:hover {
    color: black;
    background: #c9d0d2;
    font-weight: 600;
    border: 12%;
}


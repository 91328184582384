.latestUsersHeader {
    margin: 1.2rem 0;
}

.latestUsersHeader > h2{
   font-size: 1.3rem;
   font-weight: 600;
   margin-left: .8rem;
}

.widgets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1.2rem 0;
    width: 100%;
}

.widgets > .widget {
    min-width: 27vw;
    border: .5px solid rgb(235, 234, 234);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 1rem 1.2rem;
    border-radius: 10px;
}

.widgets > .widget > .user__widget-info{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: .5rem .2rem;
    padding-left: 1.23rem;
    flex: 1;
}

.widgets > .widget > .user__widget-info > .user__widget-info__title{
    color: gray;
    font-size: .85rem;
}

.widgets > .widget > .user__widget-info > .user__widget-info__value{
    font-size: 1.4rem;
    font-weight: 600;
}

.widgets > .widget > .user__widget-profit{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    border-radius: 15px;
    padding: .5rem .6rem;
    font-size: .8rem;
    /* margin-left: 1rem; */
    font-weight: 600;
}

.widgets > .widget > .user__widget-profit > svg{
    font-weight: 600;
    font-size: .9rem;
    /* color: green; */
}

.widgets > .widget > .user__widget-profit.success{
    color: green;
    background-color: rgb(189, 245, 189);
}

.widgets > .widget > .user__widget-profit.fail{
    background-color: rgb(250, 199, 199);
    color: red;
}

@media screen and (max-width: 950px) {
    .widgets {
        flex-direction: column;
        flex-wrap: wrap;
    }

    .widgets > .widget {
        margin: .7rem 0;
        width: 100%;
    }
}
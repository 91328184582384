.sidebar__menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sidebar__menu-list {
  list-style: none;
  /* padding: .5rem 1.5rem; */
  width: 100%;
  margin: 0.4rem 0;
  display: flex;
  justify-content: center;
}

.sidebar__menu-list > a {
  text-decoration: none;
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  /* margin: 0 .4rem; */
  padding: 0.5rem 0;
  border-radius: 10px;
}

.sidebar__menu-list > a > div {
  /* width: 100%; */
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  color: black;
}

.sidebar__menu-list > a > div > svg {
  margin-right: 1rem;
  margin-left: 1rem;
  color: black;
}

.sidebar__menu-list > a:hover {
  background-color: #1111;
}
